import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Yesterday, Paybefore published an op-ed written by PayPerks CEO, Arlyn Davich, and PayPerks Director of Partner Development & Consumer Impact, Rob Levy:`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "http://paybefore.com/op-ed/viewpoint-prepaid-and-financial-capability-have-grown-up-now-they-should-grow-closer/"
        }}>{`Viewpoint: Prepaid and Financial Capability Have Grown Up; Now They Should Grow Closer`}</a>{`.`}</strong></p>
    <p>{`If you don't want to click the above link to read the whole article, the key point is summarized below in these two paragraphs:`}</p>
    <blockquote>
      <p parentName="blockquote">{`For prepaid, the timing couldn’t be more prescient. As the rapid pace of industry growth over the past five years slows down somewhat, program managers and other providers are looking for ways to increase revenue from existing customers, in addition to acquiring new ones. Financial capability can be part of that answer, by driving customers toward behaviors that improve the provider’s bottom line and the consumer’s financial health at the same time.`}</p>
      <p parentName="blockquote">{`Focusing on financial capability means focusing on what actually changes consumer behavior, not just consumer knowledge. So what would it look like for prepaid and financial capability to become a closer pair? According to the foundational research on this topic by the Center for Financial Services Innovation, providers seeking to successfully incorporate the financial capability framework need to ensure the content and tools they develop adhere to four key attributes. They must be: 1) relevant 2) timely, 3) actionable and 4) ongoing.`}</p>
    </blockquote>
    <p>{`Contact Arlyn and Rob at `}<a parentName="p" {...{
        "href": "mailto:partners@payperks.com"
      }}>{`partners@payperks.com`}</a>{` if you'd like to continue the discussion.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      